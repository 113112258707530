export const environment = {
  production: true,
  BACKEND_DOMAIN: 'https://api.atlantstore.ru',
  FRONTEND_DOMAIN: 'https://atlantstore.ru',
  DIGISELLER_API_DOMAIN: 'https://api.digiseller.ru/api',
  DIGISELLER_SELLER_ID: '740905',
  ROBOKASSA_HTTP_DOMAIN: 'https://auth.robokassa.ru/Merchant/Index.aspx',
  ROBOKASSA_MERCHANT_LOGIN: 'atlantstoreru',
  ROBOKASSA_PASSWORD_1: 'IaEiB7n986szdqDgz0IT',
  ROBOKASSA_PASSWORD_2: 'eos1KGTe4bn3GqTGZ57u',
  ROBOKASSA_PASSWORD_1_TEST: 'Hv4jbR3WJ4RqGUgN94rc',
  ROBOKASSA_PASSWORD_2_TEST: 'HWc2dhiBQnG1FZ10qc5a',
  FREEKASSA_HTTP_DOMAIN: 'https://pay.freekassa.com/',
  FREEKASSA_ID: '52454',
  FREEKASSA_PASSWORD_1: 'V/olM[.v^hzfPrw',
  FREEKASSA_PASSWORD_2: 'kyY0Ns]!QJabh2(',
  PAGES_HOME_REVIEWS_ROWS: 100,
  PAGE_SIZE: 10,
  '1MB': 1024 * 1024 * 1,
};
